// Client
import { AxiosResponse, CancelTokenSource } from 'axios';
import { setHttpClient, token } from '../client';

// Definitions
import { Resource } from '../../../../domain/model';
import * as util from '../../../provider/util'
interface Props {
  id?: string;
  query?: string;
}

// Resource
const resource = '/resources';

// Dynamic token source
let tokenSource: CancelTokenSource;

export default {
  cancel: (message = 'Llamada cancelada') => {
    return tokenSource.cancel(message);
  },
  async getOne(props: Props): Promise<AxiosResponse<Resource>> {
    tokenSource = token.source();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.get(`${resource}/one${props.query}`, {
      cancelToken: tokenSource.token
    });
  }
};
