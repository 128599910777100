import React, { createContext, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// Client definition
import config from '../../../domain/client.json';

// Definitions
type Data = {
  title: string;
  color: string;
};

type Methods = {
  set: (key: keyof Data, value: any) => void;
};

type Provider = {
  data: Data;
  methods: Methods;
};

// Defaults
const defaultData: Data = {
  color: '',
  title: ''
};

const defaultMethods: Methods = {
  set: (key: keyof Data, value: any) => {}
};

/**
 * Provider
 * @constructor
 */
const Provider = (): Provider => {
  // State
  const [data, setData] = useState<Data>(defaultData);

  // Methods
  const methods: Methods = {
    set: (key: keyof Data, value: any) => {
      setData({ ...data, ...{ [key]: value } });
    }
  };

  // On-Init
  useEffect(() => {
    if (config) {
      setData({
        ...data,
        ...{
          title: config.title,
          color: config.color
        }
      });
    }
  }, []);

  return {
    data,
    methods
  };
};

/**
 * Context
 */
const Context = createContext<Provider>({
  data: defaultData,
  methods: defaultMethods
});

/**
 * Component provider
 * @param children
 * @constructor
 */
const ClientProvider = ({ children }: any) => {
  const client = Provider();
  const { data } = client;
  return (
    <Context.Provider value={client}>
      <Helmet>
        <title>{data.title}</title>
        <meta name="theme-color" content={data.color} />
      </Helmet>
      {children}
    </Context.Provider>
  );
};

/**
 * Component hook
 */
const useClient = () => {
  return useContext(Context);
};

export { ClientProvider, useClient };
