import axios from 'axios';

// Client API configuration
import { api } from '../../../domain/client.json';

import { default as util}  from '../../provider/util'
// Creating the instance for axios
function setHttpClient(headers = {}) {
  api['headers'] = {...api['headers'], ...headers}
  const httpClient = axios.create(api);
  httpClient.interceptors.response.use((response) =>  {
    return response
  }, (error) => {
    if (error.response.status == 401) {
      util.deleteAllCookies();
      sessionStorage.setItem('mensaje', 'Vencio su sesión')
      window.location.reload();

    }
  });
  return httpClient;
}

// Creating token for instance
const token = axios.CancelToken;
const httpClient = setHttpClient();
// Clear default headers
//httpClient.defaults.headers.common = {};

/*

// Auth token interceptors
const authInterceptor = (config: any) => {
  // TODO: Add auth token
  return config;
};

// Logger interceptors
const loggerInterceptor = (config: any) => {
  // TODO
  return config;
};

// Adding the request interceptors
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

// Adding the response interceptors
httpClient.interceptors.response.use(
  response => {
    // TODO: Add any response interceptors
    return response;
  },
  error => {
    // TODO: Do something with response error
    return Promise.reject(error);
  },
);
*/

export { httpClient, token, setHttpClient };
