import format from 'date-fns/format';

// Client
import { AxiosResponse, CancelTokenSource } from 'axios';
import { token, setHttpClient } from '../client';

// Definitions
import { Blocking, ExclusionReason } from '../../../../domain/model';
import * as util from '../../../provider/util'

// Resource
const resource = '/schedules/blocks';

// Dynamic token source
let source: CancelTokenSource;

// Definitions
type GetProps = {
  practitionerId: string;
  validFrom: Date;
};

type SetProps = {
  practitionerId: string;
  from: Date;
  to: Date;
  type: Blocking['exclusionType'];
  reason: string;
  id?: string;
};

type DelProps = {
  blockId: Blocking['id'];
};

/**
 * Custom Date Format
 * @param date
 * @return String with special date format
 */
const customDateFormat = (date: Date) => {
  const customDate = format(date, 'yyyy-MM-dd');
  const customHour = format(date, 'HH:mm:ssXXX');
  return `${customDate}T${customHour}`;
};

export default {
  cancel: () => source?.cancel('Service cancelled'),
  getExclusionReasons: async (): Promise<AxiosResponse<ExclusionReason[]>> => {
    const url = `${resource}/exclusion-reasons?$select=Id,ShortId,Description,IsExternalReason,ShowExtraInformation,ExtraInformationMandatory,Active,RowVersion,Cancelled&$top=10&$count=true`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  get: async (props: GetProps): Promise<AxiosResponse<Blocking[]>> => {
    const { practitionerId, validFrom } = props;
    const validFromDate = validFrom?.toISOString();
    source = token.source();
    let url = `${resource}?filter=validFrom ge ${validFromDate}`;
    url += '&expand=practitioner,healthcareService';
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  create: async (props: SetProps): Promise<AxiosResponse> => {
    const { practitionerId, from, to, reason, type } = props;
    source = token.source();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.post(
      `${resource}`,
      {
        from: customDateFormat(from),
        to: customDateFormat(to),
        repetition: '1',
        unit: 'Weekly',
        type: 'Exclude',
        exclusionType: type,
        exclusionReasonId: reason,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      { cancelToken: source.token }
    );
  },
  update: async (props: SetProps): Promise<AxiosResponse> => {
    const { practitionerId, from, to, reason, type, id } = props;
    source = token.source();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.patch(
      `${resource}/${id}`,
      {
        from: customDateFormat(from),
        to: customDateFormat(to),
        repetition: '1',
        unit: 'Weekly',
        type: 'Exclude',
        exclusionType: type,
        exclusionReasonId: reason,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      { cancelToken: source.token }
    );
  },
  delete: async (props: DelProps): Promise<AxiosResponse> => {
    const { blockId } = props;
    source = token.source();
    const url = `${resource}/${blockId}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.delete(url, { cancelToken: source.token });
  },
  decline: async (props: DelProps): Promise<AxiosResponse> => {
    const { blockId } = props;
    source = token.source();
    const url = `${resource}/decline/${blockId}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.delete(url, { cancelToken: source.token });
  }
};
