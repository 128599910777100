// Client
import { httpClient, setHttpClient } from '../client';
import * as util from '../../../provider/util'

// Resource
const resource = '/users/credentials';

export default {
  async login(username: string, password: string) {
    return httpClient.post(`${resource}/check`, {
      username,
      password
    });
  },
  async updatePassword(id: string, password: string) {
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.post(`/users/${id}/update/password`, {
      password
    });
  }
};
