// Client
import { httpClient, token, setHttpClient } from '../client';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Appointment } from '../../../../domain/model';
import * as util from '../../../provider/util'

// Resource
const resource = '/appointments';

// Dynamic token source
let source: CancelTokenSource;

// Definitions
type GetFromPractitionerProps = {
  practitionerId: string;
  from?: Date;
  to?: Date;
};

type GetFromPractitionerAndPatientProps = {
  practitionerId: string;
  patientId: string;
  from?: Date;
  to?: Date;
  asc?: boolean;
};

type CalendarResponse<TValue> = {
  [id: string]: TValue;
};

type AppointmentsToken = {
  response: Appointment[],
  token: string
}

type Stats = {
  confirmed: number,
  assisted: number,
  booked: number,
}

type AppointmentsStatsToken = {
  appointments: Appointment[],
  stats: Stats,
  token: string
}

type AppointmentToken = {
  response: Appointment,
  token: string
}

type CalendarToken = {
  response: CalendarResponse<boolean>,
  token: string
}

export default {
  cancel: () => source?.cancel('Service cancelled'),
  async getOne(id: string): Promise<AxiosResponse<AppointmentToken>> {
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    source = token.source();
    return await httpClient.get(`${resource}/${id}`, {
      cancelToken: source.token
    });
  },
  async getFromPractitioner(
    practitionerId: string
  ): Promise<AxiosResponse<AppointmentsToken>> {
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    source = token.source();
    const url = `${resource}?practitioner=${practitionerId}&expand=patient,healthcareService`;
    return await httpClient.get(url, { cancelToken: source.token });
  },
  async getFromPractitionerAndDateRange(
    props: GetFromPractitionerProps
  ): Promise<AxiosResponse<AppointmentsToken>> {
    const { practitionerId, from: fromDate, to: toDate } = props;
    const from = fromDate?.toISOString();
    const to = toDate?.toISOString();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    const url = `${resource}/practioner?filter=from ge ${from} and to le ${to}&expand=patient,healthcareService,area,speciality&sort=from asc`;
    source = token.source();
    let response : any = await httpClient.get(url, { cancelToken: source.token })
    return response;
  },
  async getFromPractitionerAndDateRangeAndStats(
    props: GetFromPractitionerProps
  ): Promise<AxiosResponse<AppointmentsStatsToken>> {
    const { practitionerId, from: fromDate, to: toDate } = props;
    const from = fromDate?.toISOString();
    const to = toDate?.toISOString();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    const url = `${resource}/practioner-stats?filter=from ge ${from} and to le ${to}&expand=patient,healthcareService,area,speciality&sort=from asc`;
    source = token.source();
    let response : any = await httpClient.get(url, { cancelToken: source.token })
    return response;
  },
  async getFromPractitionerAndPatient(
    practitionerId: string,
    patientId: string
  ): Promise<AxiosResponse<AppointmentsToken>> {
    source = token.source();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    const url = `${resource}/practioner?filter=patient eq ${patientId}&expand=patient,healthcareService,speciality`;
    return await httpClient.get(url, { cancelToken: source.token });
  },
  async getFromPractitionerAndPatientAndDateRange(
    props: GetFromPractitionerAndPatientProps
  ): Promise<AxiosResponse<AppointmentsToken>> {
    const {
      practitionerId,
      patientId,
      from: fromDate,
      to: toDate,
      asc = true
    } = props;
    const from = fromDate?.toISOString();
    const to = toDate?.toISOString();
    source = token.source();

    let url = `${resource}/practioner?&filter=patient eq ${patientId} and `;

    // Range
    if (from && to) {
      url += `from ge ${from} and to le ${to} `;
    } else if (from && !to) {
      url += `from ge ${from} `;
    } else if (!from && to) {
      url += `to le ${to} `;
    }

    // Other filters
    url += '&expand=patient,healthcareService,speciality&sort=from ';

    // Order
    url += asc ? 'asc' : 'desc';
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  async getCalendarDays(
    props: GetFromPractitionerProps
  ): Promise<AxiosResponse<CalendarToken>> {
    const { practitionerId, from: fromDate, to: toDate } = props;
    toDate?.setHours(23,59,59);
    const from = fromDate?.toISOString();
    const to = toDate?.toISOString();
    source = token.source();
    const url = `${resource}/calendars/days/practioner?filter=from ge ${from} and from lt ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''});
    let response : any = await httpClient.get(url, { cancelToken: source.token });
    return response;
  }
};
