import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// Route list
import Backdrop from '../../presentation/components/atoms/Backdrop';
import Page404 from '../../presentation/components/templates/Page404';

// Provider
import { useAuth } from '../provider/UseAuth';
import * as util from '../provider/util';

// Components
const Menu = lazy(() => import('../../presentation/components/pages/Menu'));
const File = lazy(() => import('../../presentation/components/pages/File'));
const Login = lazy(() => import('../../presentation/components/pages/Login'));
const Account = lazy(() =>
  import('../../presentation/components/pages/Account')
);
const Patients = lazy(() =>
  import('../../presentation/components/pages/Patients')
);
const Blocking = lazy(() =>
  import('../../presentation/components/pages/Blocking')
);
const Appointments = lazy(() =>
  import('../../presentation/components/pages/Appointments')
);
const Profile = lazy(() =>
  import('../../presentation/components/pages/Profile')
);
const ProfileEdit = lazy(() =>
  import('../../presentation/components/pages/Profile/ProfileEdit')
);

const ChangePassword = lazy(() =>
  import('../../presentation/components/pages/ChangePassword')
);

const Router = () => {
  const auth = useAuth();
  const practitionerId = auth.methods?.getResourceId() ||  util.default.getCookie('resourceId');
  console.log('aca: ', practitionerId ? 'menu' : 'login')
  return (
    <BrowserRouter>
      <Suspense fallback={<Backdrop />}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>
          <Route path="/login" exact>
            {practitionerId ? <Redirect to="/menu" /> : <Login />}
          </Route>
          <Route path="/menu" exact>
            {practitionerId ? <Menu /> : <Redirect to="/login" />}
          </Route>
          <Route path="/account" exact>
            {practitionerId ? <Account /> : <Redirect to="/login" />}
          </Route>
          <Route path="/appointments" exact>
            {practitionerId ? <Appointments /> : <Redirect to="/login" />}
          </Route>
          <Route path="/history/:patientId/:appointmentDate?" exact>
            {practitionerId ? <File /> : <Redirect to="/login" />}
          </Route>
          <Route path="/find" exact>
            {practitionerId ? <Patients /> : <Redirect to="/login" />}
          </Route>
          <Route path="/blocking" exact>
            {practitionerId ? <Blocking /> : <Redirect to="/login" />}
          </Route>
          <Route path="/profile" exact>
            {practitionerId ? <Profile /> : <Redirect to="/login" />}
          </Route>
          <Route path="/profile/edit" exact>
            {practitionerId ? <ProfileEdit /> : <Redirect to="/login" />}
          </Route>
          <Route path="/change-password" exact>
            {practitionerId ? <ChangePassword /> : <Redirect to="/login" />}
          </Route>
          <Route>
            <Page404 />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
