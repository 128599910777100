import {
  Button,
  createStyles,
  makeStyles,
  Snackbar,
  Theme
} from '@material-ui/core';
import React from 'react';

import {
  useWorker,
  STATUS
} from '../../../../infrastructure/provider/UseWorker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbarButton: {
      backgroundColor: theme.palette.primary.main,
      padding: '0.5em 1em'
    }
  })
);

const SnackbarUpdateVersion = () => {
  const update = useWorker();
  const classes = useStyles();

  return (
    <Snackbar
      open={update.status === STATUS.UPDATE}
      message="Hay una nueva versión disponible!"
      onClick={update.methods.setForceUpdate}
      data-test-id="screens-new-version-snackbar"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      style={{ bottom: '3em' }}
      action={
        <Button
          className={classes.snackbarButton}
          color="inherit"
          size="small"
          onClick={update.methods.setForceUpdate}
        >
          Actualizar
        </Button>
      }
    />
  );
};

export default SnackbarUpdateVersion;
