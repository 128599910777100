import React, { useEffect } from 'react';

// Infrastructure
import { CookiesProvider } from 'react-cookie';
import Router from '../infrastructure/router';

// Providers
import { AuthProvider } from '../infrastructure/provider/UseAuth';
import { UpdateProvider } from '../infrastructure/provider/UseWorker';
import { ClientProvider } from '../infrastructure/provider/UseClient';
import { default as ThemeProvider } from '../infrastructure/provider/UseTheme';
import Sked24 from '../infrastructure/services/sked24';
import SnackbarUpdateVersion from './components/atoms/SnackbarUpdateVersion';

function App() {
  return (
    <div className="pwa-doctor">
      <ClientProvider>
        <UpdateProvider>
          <CookiesProvider>
            <AuthProvider>
              <ThemeProvider>
                <SnackbarUpdateVersion />
                <Router />
              </ThemeProvider>
            </AuthProvider>
          </CookiesProvider>
        </UpdateProvider>
      </ClientProvider>
    </div>
  );
}

export default App;
