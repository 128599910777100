// Client
import { httpClient, token, setHttpClient } from '../client';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Patient } from '../../../../domain/model';
import * as util from '../../../provider/util'

// Resource
const resource = '/patients';

// Dynamic token source
let source: CancelTokenSource;
type PatientToken = {
  response: Patient,
  token: string
}
export default {
  cancel: () => source?.cancel('Service cancelled'),
  async getOne(id: string): Promise<AxiosResponse<PatientToken>> {
    source = token.source();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(`${resource}/${id}?expand=coverages`, {
      cancelToken: source.token
    });
  }
};
