import axios from 'axios';

// Client API configuration
import { api } from '../../../domain/client.json';

// Creating the instance for axios
const newBackendHttpClient = axios.create({
  baseURL: api.baseURLNewBackend,
  headers: api.headers
});

// Creating token for instance
const token = axios.CancelToken;

export { newBackendHttpClient, token };
