import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

// Components
import {
  Backdrop as MaterialBackdrop,
  LinearProgress
} from '@material-ui/core';

const Backdrop = () => {
  const classes = useStyles();

  return (
    <MaterialBackdrop className={classes.backdrop} open={true}>
      <LinearProgress />
    </MaterialBackdrop>
  );
};

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#f9fbfc'
    }
  })
);

export default Backdrop;
