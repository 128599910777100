import React, { createContext, useContext, useEffect, useState } from 'react';

// Service worker
import * as serviceWorker from '../../../serviceWorker';

// Definitions
import STATUS from './status';

type Methods = {
  setForceUpdate: () => void;
};

type Provider = {
  status: STATUS;
  methods: Methods;
};

/**
 * Default response
 */
const defaultValue: Provider = {
  status: STATUS.INIT,
  methods: {
    setForceUpdate: () => {}
  }
};

/**
 * Hook
 */
const useProvider = (): Provider => {
  const [status, setStatus] = useState<STATUS>(STATUS.INIT);
  const [require, setRequire] = useState(false);
  const [register, setRegister] = useState<ServiceWorkerRegistration>();

  // Internal methods
  const internal = {
    setOffline: () => {
      setStatus(STATUS.OFFLINE);
    },
    setInitialState: () => {
      setStatus(STATUS.INIT);
      setRequire(false);
    },
    setRegister: (newRegister: ServiceWorkerRegistration) => {
      console.log('on update. set new register');
      console.log('new register:');
      console.log(newRegister);
      setStatus(STATUS.UPDATE);
      setRequire(true);
      setRegister(newRegister);
    }
  };

  // Public methods
  const methods: Methods = {
    setForceUpdate: () => {
      if (require) {
        // If user require a update
        if (status === STATUS.UPDATE) {
          if (register && register.waiting) {
            register.waiting.postMessage({ type: 'SKIP_WAITING' });
            internal.setInitialState();
            window.location.reload();
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   if (require) {
  //     // If user require a update
  //     if (status === STATUS.UPDATE) {
  //       if (register && register.waiting) {
  //         register.waiting.postMessage({ type: 'SKIP_WAITING' });
  //         window.location.reload();
  //       }
  //     }
  //   }

  //   return () => internal.setInitialState();
  // }, [require, register, status]);

  // On-load
  useEffect(() => {
    if (!register) {
      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA
      serviceWorker.register({
        onOffline: () => internal.setOffline(),
        onSuccess: () => internal.setInitialState(),
        onUpdate: registration => internal.setRegister(registration)
      });
    }
  }, []);

  return { status, methods };
};

/**
 * Context
 */
const UpdateContext = createContext<Provider>(defaultValue);

/**
 * Provider component
 * @param children
 * @constructor
 */
const UpdateProvider = ({ children }: any) => {
  const update = useProvider();
  return (
    <UpdateContext.Provider value={update}>{children}</UpdateContext.Provider>
  );
};

/**
 * Consumer
 */
const useWorker = () => {
  return useContext(UpdateContext);
};

export { UpdateProvider, useWorker };
