import React, { useEffect, useState } from 'react';
import { Diez, Language } from 'design-language';
import { createMuiTheme, ThemeProvider, Theme } from '@material-ui/core/styles';

type Props = {
  children?: any;
};

const MyTheme = (props: Props) => {
  const { children } = props;
  const [createdTheme, setCreatedTheme] = useState<Theme>(createMuiTheme({}));

  useEffect(() => {
    if (Language && Diez) {
      const language = new Diez(Language);
      language &&
        language.attach(ds => {
          setCreatedTheme(
            createMuiTheme({
              palette: {
                primary: {
                  main: ds.colors.primary.color,
                  light: ds.colors.primaryLight.color,
                  contrastText: ds.colors.white.color
                },
                success: {
                  main: ds.colors.success.color
                },
                warning: {
                  main: ds.colors.warning.color
                },
                error: {
                  main: ds.colors.error.color
                },
                text: {
                  primary: ds.colors.black.color
                }
              },
              typography: {
                fontFamily: 'Nunito, sans-serif',
                allVariants: {
                  fontFamily: 'Nunito, sans-serif'
                },
                h1: {
                  fontSize: ds.typography.h1.style.fontSize,
                  fontWeight: 300
                },
                h2: {
                  fontSize: ds.typography.h2.style.fontSize,
                  fontWeight: 300
                },
                h3: {
                  fontSize: ds.typography.h3.style.fontSize,
                  fontWeight: 400
                },
                h4: {
                  fontSize: ds.typography.h4.style.fontSize,
                  fontWeight: 400
                },
                h5: {
                  fontSize: ds.typography.h5.style.fontSize,
                  fontWeight: 'bold'
                },
                h6: {
                  fontSize: ds.typography.h6.style.fontSize,
                  fontWeight: 600
                },
                subtitle1: {
                  fontSize: ds.typography.subtitle1.style.fontSize,
                  fontWeight: 400
                },
                subtitle2: {
                  fontSize: ds.typography.subtitle2.style.fontSize,
                  fontWeight: 600
                },
                body1: {
                  fontSize: ds.typography.body1.style.fontSize,
                  fontWeight: 400
                },
                body2: {
                  fontSize: ds.typography.body2.style.fontSize,
                  fontWeight: 400
                },
                button: {
                  fontSize: ds.typography.button.style.fontSize,
                  fontWeight: 600
                },
                caption: {
                  fontSize: ds.typography.caption.style.fontSize,
                  fontWeight: 400
                },
                overline: {
                  fontSize: ds.typography.overline.style.fontSize,
                  fontWeight: 400
                }
              }
            })
          );
        });
    }
  }, []);

  return <ThemeProvider theme={createdTheme}>{children}</ThemeProvider>;
};

export default MyTheme;
