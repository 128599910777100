// HTTP Client
import { httpClient, token, setHttpClient } from '../client';

// Client config
import { fonasaCode } from '../../../../domain/client.json';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Stats } from '../../../../domain/model';
import * as util from '../../../provider/util'

// Resource
const resource = '/stats';

// Dynamic token source
let source: CancelTokenSource;

type PropsStats = {
  practitionerId: string;
  from: Date;
  to: Date;
};

export default {
  cancel: () => source?.cancel('Service cancelled'),
  getConfirmed: async (props: PropsStats): Promise<AxiosResponse<Stats>> => {
    const { practitionerId, from: fromDate, to: toDate } = props;
    source = token.source();
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    let url = `${resource}/appointments-confirmed`;
    url += `?filter=from ge ${from} and to le ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  getBooked: async (props: PropsStats): Promise<AxiosResponse<Stats>> => {
    const { practitionerId, from: fromDate, to: toDate } = props;
    source = token.source();
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    let url = `${resource}/appointments-not-performed-onpast`;
    url += `?filter=from ge ${from} and to le ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  getAssisted: async (props: PropsStats): Promise<AxiosResponse<Stats>> => {
    const { practitionerId, from: fromDate, to: toDate } = props;
    source = token.source();
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    let url = `${resource}/appointments-assisted`;
    url += `?filter=from ge ${from} and to le ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  getNotPerfomedOnPast: async (props: PropsStats): Promise<AxiosResponse<Stats>> => {
    const { practitionerId, from: fromDate, to: toDate } = props;
    source = token.source();
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    let url = `${resource}/appointments-not-performed-onpast`;
    url += `?filter=from ge ${from} and to le ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  getTotal: async (props: PropsStats): Promise<AxiosResponse<Stats>> => {
    const { practitionerId, from: fromDate, to: toDate } = props;
    source = token.source();
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    let url = `${resource}/appointments-total-booked`;
    url += `?filter=from ge ${from} and to le ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  getNoShow: async (props: PropsStats): Promise<AxiosResponse<Stats>> => {
    const { practitionerId, from: fromDate, to: toDate } = props;
    source = token.source();
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    let url = `${resource}/past/appointments-noshow`;
    url += `?filter=from ge ${from} and to le ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  },
  getCoverage: async (props: PropsStats): Promise<AxiosResponse<Stats>> => {
    const { practitionerId, from: fromDate, to: toDate } = props;
    source = token.source();
    const from = fromDate.toISOString();
    const to = toDate.toISOString();
    let url = `${resource}/appointments-coverage`;
    url += `?filter=coverage_shortid eq ${fonasaCode} and `;
    url += `from ge ${from} and to le ${to}`;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return await httpClient.get(url, { cancelToken: source.token });
  }
};
