import React from 'react';
import ReactDOM from 'react-dom';

// Style
import './index.css';

// Components
import App from './presentation/App';

ReactDOM.render(<App />, document.getElementById('root'));
