// Client
import { AxiosResponse, CancelTokenSource } from 'axios';
import { setHttpClient, token } from '../client';

// Definitions
import { Speciality } from '../../../../domain/model';
import * as util from '../../../provider/util'

interface Props {
  id?: string;
  query?: string;
}

// Resource
const resource = '/specialties';

// Dynamic token source
let tokenSource: CancelTokenSource;

export default {
  cancel: (message = 'Llamada cancelada') => {
    return tokenSource.cancel(message);
  },
  async get(props: Props): Promise<AxiosResponse<Speciality>> {
    tokenSource = token.source();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.get(`${resource}/${props.query}`, {
      cancelToken: tokenSource.token
    });
  },
  async getOne(props: Props): Promise<AxiosResponse<Speciality>> {
    tokenSource = token.source();
    const { query = '' } = props;
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.get(`${resource}/${props.id}${query}`, {
      cancelToken: tokenSource.token
    });
  }
};
