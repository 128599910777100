// Client
import { AxiosResponse, CancelTokenSource } from 'axios';
import { /*httpClient,*/ token, setHttpClient } from '../client';

// Definitions
import { Physician } from '../../../../domain/model';
import * as util from '../../../provider/util'

export type UpdatePhysicianBody = {
  op: string;
  path: string;
  value: string;
};

export type PhysicianToken = {
  token: string;
  response: Physician;
};

// Resource
const resource = '/physicians';

// Dynamic token source
let tokenSource: CancelTokenSource;


export default {
  cancel: (message = 'Llamada cancelada') => {
    return tokenSource.cancel(message);
  },
  async getOne(id: string): Promise<AxiosResponse<PhysicianToken>> {
    tokenSource = token.source();
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.get(`${resource}`, {
      cancelToken: tokenSource.token
    });
  },
  async update(
    id: string,
    data: UpdatePhysicianBody[]
  ): Promise<AxiosResponse<Physician>> {
    const httpClient = setHttpClient({'Authorization': util.default.getCookie('token') ?? ''})
    return httpClient.patch(`${resource}`, data);
  },
  async getPictureUri(id: string): Promise<string> {
    return `${resource}/picture`;
  }
};
