// Client
import { AxiosResponse } from 'axios';
import { newBackendHttpClient as httpClient } from '../client-new-backend-node';

// Resource
const resource = '/doctor-logger';

export type saveLogProps = {
  firstName: string;
  lastName: string;
  skedId: string;
  rut: string;
  email: string;
};

export type Doctor = {
  id: number;
  skedId: string;
  rut: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export default {
  async getAll() {
    return httpClient.get(`${resource}`);
  },
  async findOne(id: string): Promise<AxiosResponse<Doctor>> {
    return httpClient.get(`${resource}/${id}`);
  },
  async saveLog(body: saveLogProps) {
    return httpClient.post(`${resource}`, body);
  }
};
