import appointment from './repositories/appointment';
import user from './repositories/users';
import doctorLogger from './repositories/doctorLogger';
import patient from './repositories/patient';
import resources from './repositories/resources';
import physicians from './repositories/physician';
import stats from './repositories/stats';
import specialties from './repositories/specialties';
import schedulesBlocks from './repositories/schedulesBlocks';

export default {
  appointmentService: appointment,
  userService: user,
  patientService: patient,
  resourceService: resources,
  physicianService: physicians,
  statsService: stats,
  schedulesBlocksService: schedulesBlocks,
  specialtiesService: specialties,
  doctorLoggerService: doctorLogger
};
