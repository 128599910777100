import { parseISO, format as formatDate } from 'date-fns';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { format } from 'rut.js';
import Physician from '../../../domain/model/physician';
// Services
import Sked24 from '../../services/sked24';
import doctorLoggerService, {
  saveLogProps
} from '../../services/sked24/repositories/doctorLogger';

// Definitions
type Methods = {
  setDoctor: (doctor: Physician) => void;
  formattingRut: (rut?: string) => string;
  formattingDate: (date?: string) => string;
  formattingPhone: (phone?: string) => string;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
  getResourceId: () => string;
  deleteAllCookies: () => void;
};

type Provider = {
  userId: string;
  physicianId: string;
  physician: Physician;
  physicianPicture: string;
  methods?: Methods;
};

/**
 * Function
 */
const useProvideAuth = (): Provider => {
  // Services
  const { userService, physicianService } = Sked24;

  // Hooks
  const [cookies, setCookies, removeCookie] = useCookies(['med-360']);

  // State
  const [userId, setUserId] = useState('');
  const [physicianId, setPhysicianId] = useState('');
  const [physician, setPhysician] = useState({} as Physician);
  const [physicianPicture, setPhysicianPicture] = useState('');

  const methods = {
    deleteAllCookies: () => {
      const browserCookies = document.cookie.split(';');
      for (let i = 0; i < browserCookies.length; i++) {
        const cookie = browserCookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    },
    getPhysician: async (resourceId: string) => {
      let physician: any = await physicianService.getOne(resourceId);
      setCookies('token', physician.data.token, {
        expires: new Date(
          new Date().setFullYear(new Date().getFullYear() + 3)
        )
      });
      return physician.data;
    },
    getResourceId: (): string => {
      return physicianId;
    },
    setDoctor: async (doctor: Physician) => {
      setPhysician(doctor);
    },
    getPhysicianPicture: async (resourceId: string) => {
      return physicianService.getPictureUri(resourceId);
    },
    signIn: async (username: string, password: string, remember = false) => {
      try {
        const { data: userData } = await userService.login(username, password);
        if (userData.resourceId) {
          setCookies('userId', userData.userId, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 3)
            )
          });
          setCookies('resourceId', userData.resourceId, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 3)
            )
          });
          setCookies('token', userData.token, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 3)
            )
          });
          // save the whole physician data in context
          const { response: physicianData  } = await methods.getPhysician(
            userData.resourceId
          );
          // save one log into new backend
          methods.saveDoctorLog(physicianData);
          setPhysician(physicianData);
          if (physicianData.id) {
            setPhysicianId(physicianData.id);
          }
          setUserId(userData.userId);
          // get physician picture and save cookie
          const picture = await methods.getPhysicianPicture(
            userData.resourceId
          );
          setPhysicianPicture(picture);

          return userData.resourceId;
        }
        throw new Error('Not user found');
      } catch (error) {
        methods.deleteAllCookies();
        return false;
      }
    },
    signOut: () => {
      methods.deleteAllCookies();
      return true;
    },
    formattingRut: (rut?: string) => {
      if (rut) {
        return format(rut);
      }
      return '';
    },
    formattingDate: (isoDate?: string) => {
      if (isoDate) {
        const date = parseISO(isoDate);
        return formatDate(date, 'dd/MM/yyyy');
      }
      return '';
    },
    formattingPhone: (phone?: string) => {
      if (phone) {
        const countryCode = '+56';
        return phone.replace(countryCode, `${countryCode} `);
      }
      return '';
    },
    confirmPasswordReset: (oldPassword: string, newPassword: string) => {},

    saveDoctorLog: async (physician: Physician) => {
      const bodyDoctorLog = {
        skedId: physician.id,
        firstName: physician.firstName1,
        lastName: physician.lastName1,
        rut: physician.documentNumber,
        email: physician.email
      } as saveLogProps;

      const doctorLog = await doctorLoggerService.saveLog(bodyDoctorLog);
    },
    loadState: async () => {
      if (cookies.resourceId) {
        const { response: physicianData } = await methods.getPhysician(
          cookies.resourceId
        );
        setPhysician(physicianData);
        // si el doctor existe, guardar log hacia el nuevo backend
        methods.saveDoctorLog(physicianData);
        // -----
        if (physicianData.id) {
          setPhysicianId(physicianData.id);
        }
        const picture = await methods.getPhysicianPicture(cookies.resourceId);
        setPhysicianPicture(picture);
      } else {
        setPhysician({});
        setPhysicianPicture('');
      }
      if (cookies.userId) setUserId(cookies.userId);
      else setUserId('');
    }
  };

  useEffect(() => {
    methods.loadState();
  }, [cookies]);

  return {
    userId,
    physicianId,
    physician,
    physicianPicture,
    methods
  };
};

/**
 * Context
 */
const authContext = createContext<Provider>({
  userId: '',
  physicianId: '',
  physician: {},
  physicianPicture: ''
});

/**
 * Provider
 * @param children
 * @constructor
 */
const AuthProvider = ({ children }: any) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

/**
 * Use Auth for components
 */
const useAuth = () => {
  return useContext(authContext);
};

export { AuthProvider, useAuth };
